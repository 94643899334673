import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId["BSC"] = 56] = "BSC";
    ChainId[ChainId["DX_CHAIN"] = 9000] = "DX_CHAIN";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
var _obj;
export var FACTORY_ADDRESS_MAP = (_obj = {}, _define_property(_obj, ChainId.BSC, "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73"), _define_property(_obj, ChainId.DX_CHAIN, "0x31C52Ba6e8B49f392f3eE08551Bad6B38BEF1fac"), _obj);
var _obj1;
export var ROUTER_ADDRESS_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.BSC, "0x10ED43C718714eb63d5aA57B78B54704E256024E"), _define_property(_obj1, ChainId.DX_CHAIN, "0x183C9A41d985D53C1A7a7493E6DedfFdf78FBCD2"), _obj1);
var _obj2;
export var MULTICALL_ADDRESS_MAP = (_obj2 = {}, _define_property(_obj2, ChainId.BSC, ""), _define_property(_obj2, ChainId.DX_CHAIN, "0x57699A527E8fE9deA95df588c9fB1Ac748E551f4"), _obj2);
var _obj3;
// Multicall block number
export var MULTICALL_BLOCK_CREATE_MAP = (_obj3 = {}, _define_property(_obj3, ChainId.BSC, 841910), _define_property(_obj3, ChainId.DX_CHAIN, 19371), _obj3);
var _obj4;
export var INIT_CODE_HASH_MAP = (_obj4 = {}, _define_property(_obj4, ChainId.BSC, "0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5"), _define_property(_obj4, ChainId.DX_CHAIN, "0xe42e4b5a3a1d1a987dab24ee835bf4ce523f8e567b8def7b3c5d47d96fe572af"), _obj4);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var FEES_NUMERATOR = JSBI.BigInt(9975);
export var FEES_DENOMINATOR = JSBI.BigInt(10000);
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj5;
export var SOLIDITY_TYPE_MAXIMA = (_obj5 = {}, _define_property(_obj5, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj5, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj5);
