import { useTranslation } from '@pancakeswap/localization'
import { Menu as UikitMenu } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { languageList } from 'config/localization/languages'
import { useGetBnbBalance, useGetPairToken } from 'hooks/useTokenBalance'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import useTheme from 'hooks/useTheme'
import { PairDataTimeWindowEnum } from 'state/swap/types'
import { DXD_ADDRESS } from 'views/Swap/components/Chart/constants'
import { PAIR_ID_WDXD_WUSD } from 'config/constants'
import { useMenuItems } from './hooks/useMenuItems'
import UserMenu from './UserMenu'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { footerLinks } from './config/footerConfig'
import GlobalSettings from './GlobalSettings'


const Menu = (props) => {
  const { isDark, setTheme } = useTheme()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { account } = useWeb3React()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useRouter()
  const menuItems = useMenuItems()

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  const toggleTheme = useMemo(() => {
    return () => setTheme(isDark ? 'light' : 'dark')
  }, [setTheme, isDark])

  const getFooterLinks = useMemo(() => {
    return footerLinks(t)
  }, [t])

  const { data: pairInfo }: any = useGetPairToken(PAIR_ID_WDXD_WUSD, PairDataTimeWindowEnum.DAY, DXD_ADDRESS)

  return (
    <UikitMenu
      linkComponent={(linkProps) => {
        return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
      }}
      rightSide={
        <>
          <GlobalSettings />
          {/* <NetworkSwitcher /> */}
          <UserMenu />
        </>
      }
      // banner={showPhishingWarningBanner && typeof window !== 'undefined' && <PhishingWarningBanner />}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      cakePriceUsd={undefined}
      // cakePriceUsd={cakePriceUsd.toNumber()}
      links={menuItems}
      subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav || activeMenuItem?.items || []}
      footerLinks={getFooterLinks}
      activeItem={activeMenuItem?.href}
      activeSubItem={activeSubMenuItem?.href}
      buyCakeLabel={t('Buy DXD')}
      balance={{ balance, fetchStatus }}
      account={account}
      tokenPrice={!pairInfo ? 0 : pairInfo[pairInfo?.length - 1]?.price}
      {...props}
    />
  )
}

export default Menu
