import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { CAKE } from 'config/constants/tokens'
import { FAST_INTERVAL } from 'config/constants'
import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber'
import { Zero } from '@ethersproject/constants'
import useSWR from 'swr'
import { BIG_ZERO } from 'utils/bigNumber'
import { bscRpcProvider } from 'utils/providers'
import fetchPairPriceData from 'state/swap/fetch/fetchPairPriceData'
import { PairDataTimeWindowEnum } from 'state/swap/types'
import { useTokenContract } from './useContract'
import { useSWRContract } from './useSWRContract'

const useTokenBalance = (tokenAddress: string) => {
  const { account } = useWeb3React()

  const contract = useTokenContract(tokenAddress, false)
  const { data, status, ...rest } = useSWRContract(
    account
      ? {
          contract,
          methodName: 'balanceOf',
          params: [account],
        }
      : null,
    {
      refreshInterval: FAST_INTERVAL,
    },
  )

  return {
    ...rest,
    fetchStatus: status,
    balance: data ? new BigNumber(data.toString()) : BIG_ZERO,
  }
}

export const useGetBnbBalance = () => {
  const { account } = useWeb3React()
  const { status, data, mutate } = useSWR([account, 'bnbBalance'], async () => {
    return bscRpcProvider.getBalance(account)
  })

  return { balance: data || Zero, fetchStatus: status, refresh: mutate }
}

export const useGetCakeBalance = () => {
  const { chainId } = useWeb3React()
  const { balance, fetchStatus } = useTokenBalance(CAKE[chainId]?.address)

  // TODO: Remove ethers conversion once useTokenBalance is converted to ethers.BigNumber
  return { balance: EthersBigNumber.from(balance.toString()), fetchStatus }
}

export const useGetPairToken = (pairId: string, timeWindow: PairDataTimeWindowEnum, token0 = '', options = {}) => {
  return useSWR(
    [pairId, timeWindow, token0, 'pairInfo'],
    async () =>
      fetchPairPriceData({ pairId, timeWindow }).then((res: any) => {
        const calculatePrice = (item) => item?.pair?.token0?.id === token0  ? +item?.reserve1 / +item?.reserve0 : +item?.reserve0 / +item?.reserve1

        if (timeWindow === PairDataTimeWindowEnum.DAY || timeWindow === PairDataTimeWindowEnum.WEEK) {
          return res?.data?.pairHourDatas?.map((item) => ({
            ...item,
            price: calculatePrice(item),
          }))
        }

        return res?.data?.pairDayDatas?.map((item) => ({
          ...item,
          price: calculatePrice(item),
        }))
      }),
    {
      refreshInterval: 30000,
      ...options,
    },
  )
}

export default useTokenBalance
