import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  gap: ",
        ";\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { flexbox } from "styled-system";
import Box from "./Box";
var Flex = styled(Box).withConfig({
    componentId: "sc-244e33f6-0"
})(_templateObject(), function(param) {
    var gap = param.gap;
    return gap || 0;
}, function(param) {
    var center = param.center;
    return center && "\n    align-items: center;\n    justify-content: center;\n  ";
}, flexbox);
export default Flex;
